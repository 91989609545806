<template>
  <main class="color-primary h-100 page_bg_grey blank-aside-js" :class="(isOpenAside == 'true') ? 'blank-aside' : ''">
    <p class="main-title weight-700">
      {{ $t('Products') }}
    </p>
    <div class="main-form">
      <div class="px-10 mb-10">
        <div class="form-block">
          <div class="form-item mb-5">
            <label>{{ $t('Select a category') }}</label>
            <vue-select :options="supplierCategory.options" class="input cus-select" v-model="supplierCategory.selected"
              @input="changeCategory"></vue-select>
            <!-- <div class="mt-2 mb-7 icon-input">
              <input type="text" class="input" />
              <img src="/assets/img/icons/search-light.svg" alt="">
            </div> -->
          </div>
          <div class="form-item mb-5">
            <label>{{ $t('Select a supplier') }}</label>
            <vue-select :options="suppliers.options" class="input cus-select" v-model="suppliers.selected"
              @input="changeSupplier"></vue-select>
          </div>
          <div class="form-item mb-5">
            <label>{{ $t('Choose a product') }}</label>
            <vue-select :options="supplierProducts.options" class="input cus-select" v-model="supplierProducts.selected"
              @input="changeProduct"></vue-select>
          </div>
          <div class="form-item mb-5">
            <label>{{ $t('Product Type') }}</label>
            <vue-select :options="cardType.options" class="input cus-select" v-model="cardType.selected"
              @input="changeType"></vue-select>
          </div>
          <div class="form-item mb-5">
            <label>{{ $t('Product name: Hebrew') }}</label>
            <div class="mb-7 icon-input">
              <input type="text" class="input" v-model="product_data.name_he" />
              <img src="/assets/img/icons/tranlate.png" alt="" class="translate-img">
            </div>
          </div>
          <div class="form-item mb-5">
            <label>{{ $t('Product name: English') }}</label>
            <div class="mb-7 icon-input">
              <input type="text" class="input" v-model="product_data.name_en" />
              <img src="/assets/img/icons/tranlate.png" alt="" class="translate-img">
            </div>
          </div>
          <div class="form-item mb-5">
            <label>{{ $t('Product name: Arabic') }}</label>
            <div class="mb-7 icon-input">
              <input type="text" class="input" v-model="product_data.name_ar" />
              <img src="/assets/img/icons/tranlate.png" alt="" class="translate-img">
            </div>
          </div>
          <div class="form-item mb-5 space-form-item_4">
          </div>
          <!-- <div class="form-item p-relative">
            <label></label>
            <button class="primary-btn add-lang_btn" @click="showAddLang = !showAddLang">
              {{ $t('Translation of additional languages') }}
            </button>
            <div class="arrow-top" v-show="showAddLang"></div>
          </div> -->
        </div>
      </div>
      <!-- <div class="p-relative">
        <div class="add-lang-block w-100" v-show="showAddLang">
          <div class="form-group d-flex flex-wrap">
            <div class="w-25 form-div px-7 mb-10">
              <label>{{ $t('Product name: English') }}</label>
              <div class="mt-2 icon-input">
                <input type="text" class="input" />
                <img src="/assets/img/icons/tranlate.png" alt="" class="translate-img">
              </div>
            </div>
            <div class="w-25 form-div px-7 mb-10">
              <label>{{ $t('Product name: English') }}</label>
              <div class="mt-2 icon-input">
                <input type="text" class="input" />
                <img src="/assets/img/icons/tranlate.png" alt="" class="translate-img">
              </div>
            </div>
            <div class="w-25 form-div px-7 mb-10">
              <label>{{ $t('Product name: English') }}</label>
              <div class="mt-2 icon-input">
                <input type="text" class="input" />
                <img src="/assets/img/icons/tranlate.png" alt="" class="translate-img">
              </div>
            </div>
            <div class="w-25 form-div px-7 mb-10">
              <label>{{ $t('Product name: English') }}</label>
              <div class="mt-2 icon-input">
                <input type="text" class="input" />
                <img src="/assets/img/icons/tranlate.png" alt="" class="translate-img">
              </div>
            </div>
            <div class="w-25 form-div px-7 mb-10">
              <label>{{ $t('Product name: English') }}</label>
              <div class="mt-2 icon-input">
                <input type="text" class="input" />
                <img src="/assets/img/icons/tranlate.png" alt="" class="translate-img">
              </div>
            </div>
            <div class="w-25 form-div px-7 mb-10">
              <label>{{ $t('Product name: English') }}</label>
              <div class="mt-2 icon-input">
                <input type="text" class="input" />
                <img src="/assets/img/icons/tranlate.png" alt="" class="translate-img">
              </div>
            </div>
            <div class="w-25 form-div px-7 mb-10">
              <label>{{ $t('Product name: English') }}</label>
              <div class="mt-2 icon-input">
                <input type="text" class="input" />
                <img src="/assets/img/icons/tranlate.png" alt="" class="translate-img">
              </div>
            </div>
            <div class="w-25 form-div px-7 mb-10">
              <label>{{ $t('Product name: English') }}</label>
              <div class="mt-2 icon-input">
                <input type="text" class="input" />
                <img src="/assets/img/icons/tranlate.png" alt="" class="translate-img">
              </div>
            </div>
          </div>
          <div class="px-7  mt-20 mb-20">
            <button class="primary-btn btn-limit-width">
              {{ $t('Save') }}
            </button>
          </div>
        </div>
      </div> -->
      <div class="px-10 mb-5">
        <div class="form-block">
          <div class="form-item width-33">
            <label>{{ $t('Product description: Hebrew') }}</label>
            <vue-editor class="mt-2 mb-7 bg-white" v-model="product_data.description_he"></vue-editor>
          </div>
          <div class="form-item width-33">
            <label>{{ $t('Product description: English') }}</label>
            <vue-editor class="mt-2 mb-7 bg-white" v-model="product_data.description_en"></vue-editor>
          </div>
          <div class="form-item width-33">
            <label>{{ $t('Product description: Arabic') }}</label>
            <vue-editor class="mt-2 mb-7 bg-white" v-model="product_data.description_ar"></vue-editor>
          </div>
          <div class="form-item space-form-item_3">
          </div>
        </div>
      </div>
      <div class="bdbr-1 px-10 mb-5">
        <p class="sub-title weight-700 mt-2">
          {{ $t('Product Details') }}
        </p>
        <div class="form-block">
          <div class="form-item">
            <label>{{ $t('Minutes of conversation') }}</label>
            <input type="text" class="input mt-2 mb-7" v-model="product_data.call_minute" />
          </div>
          <div class="form-item">
            <label>{{ $t('SMS messages') }}</label>
            <input type="text" class="input mt-2 mb-7" v-model="product_data.sms" />
          </div>
          <div class="form-item">
            <label>{{ $t('Mobile browsing') }}</label>
            <input type="text" class="input mt-2 mb-7" v-model="product_data.browsing_package" />
          </div>
          <div class="form-item">
            <label>{{ $t('Terms of calls') }}</label>
            <input type="text" class="input mt-2 mb-7" v-model="product_data.call_terms" />
          </div>
          <div class="form-item">
            <label>{{ $t('General') }} 1</label>
            <input type="text" class="input mt-2 mb-7" v-model="product_data.other1" />
          </div>
          <div class="form-item">
            <label>{{ $t('General') }} 2</label>
            <input type="text" class="input mt-2 mb-7" v-model="product_data.other2" />
          </div>
          <div class="form-item">
            <label>{{ $t('Price use abroad Israel') }}</label>
            <input type="text" class="input mt-2 mb-7" v-model="product_data.abroed_price" />
          </div>
          <div class="form-item">
            <label>{{ $t('Valid on days') }}</label>
            <input type="text" class="input mt-2 mb-7" v-model="product_data.usage_time_limit" />
          </div>
          <div class="form-item space-form-item_3">
          </div>
        </div>
      </div>
      <div class="px-10 mb-5">
        <div class="form-block align-items-center">
          <div class="form-checkbox">
            <v-checkbox color="#0D3856" class="cus-checkbox" v-model="product_data.status"></v-checkbox>
            <label class="px-5">{{ $t('Active') }}</label>
          </div>
          <div class="form-checkbox">
            <v-checkbox color="#0D3856" class="cus-checkbox" v-model="product_data.cancelable"></v-checkbox>
            <label class="px-5">{{ $t('Authorized for revocation') }}</label>
          </div>
          <div class="form-checkbox">
            <v-checkbox color="#0D3856" class="cus-checkbox" v-model="product_data.default_is_allow"></v-checkbox>
            <label class="px-5">{{ $t('Default in setting up a reseller') }}</label>
          </div>
          <div class="form-checkbox">
            <v-checkbox color="#0D3856" class="cus-checkbox" v-model="product_data.add_vat"></v-checkbox>
            <label class="px-5">{{ $t('Add Vat') }}</label>
          </div>
          <div class="form-item">
            <vue-select :options="test.options" class="input cus-select" v-model="test.selected"></vue-select>
          </div>
        </div>
      </div>
      <div class="bdbr-1 px-10 mb-10">
        <p class="sub-title weight-700 mt-2">
          {{ $t('Product settings') }}
        </p>
        <div class="form-block">
          <div class="form-item d-flex">
            <div>
              <label>{{ $t('Provider') }}</label>
              <select class="input mt-2 mb-7" v-model="product_data.provider_type">
                <option v-for="item in product_data.provider_type_api" :value="item"
                  :selected="product_data.provider_type === item">{{ item }}</option>
              </select>
            </div>
            <div>
              <label>{{ $t('Supplier ID') }}</label>
              <input type="text" class="input mt-2 mb-7" v-model="product_data.supplier_identity_number" />
            </div>
          </div>
          <div class="form-item">
            <label>{{ $t('iCount Product Number in') }}</label>
            <input type="text" class="input mt-2 mb-7" />
          </div>
          <div class="form-item">
            <label>{{ $t('Order') }}</label>
            <input type="text" class="input mt-2 mb-7" v-model="product_data.order" />
          </div>
          <div class="form-item">
            <label>{{ $t('Profit model') }}</label>
            <vue-select :options="profitList.options" class="input cus-select mt-2 mb-7"
              v-model="profitList.selected"></vue-select>
          </div>
          <div class="form-item">
            <label class="block-label"></label>
            <div class="form-checkbox mt-2">
              <v-checkbox color="#0D3856" class="cus-checkbox" v-model="product_data.manual_price"></v-checkbox>
              <label class="px-5">{{ $t('Manual Price') }}</label>
            </div>
          </div>
          <div class="form-item">
            <label>{{ $t('Business Sale Price') }}</label>
            <input type="text" class="input mt-2 mb-7" v-model="product_data.price" />
          </div>
          <div class="form-item">
            <!-- This price will be use in customer site (enduser) -->
            <label>{{ $t('Customer Sale Price') }}</label>
            <input type="text" class="input mt-2 mb-7" v-model="product_data.customer_price" />
          </div>
          <div class="form-item">
            <label>{{ $t('Distribution fee') }}</label>
            <input type="text" class="input mt-2 mb-7" v-model="product_data.distribution_fee" />
          </div>
          <div class="form-item">
            <label>{{ $t('Purchase price') }}</label>
            <input type="text" class="input mt-2 mb-7" v-model="product_data.buying_price" />
          </div>
          <div class="form-item">
            <label>{{ $t('Currency') }}</label>
            <vue-select :options="currencyList.options" class="input cus-select mt-2 mb-7"
              v-model="currencyList.selected"></vue-select>
          </div>
          <div class="form-item">
            <label>{{ $t('Alternative Product (recommended)') }}</label>
            <vue-select :options="alternativeProducts.options" class="input cus-select mt-2 mb-7"
              v-model="alternativeProducts.selected"></vue-select>
          </div>
          <div class="form-item space-form-item_3">
          </div>
          <div class="form-item space-form-item_4">
          </div>
          <div class="form-item space-form-item_4">
          </div>
        </div>
      </div>
      <div class="bdbr-1 px-10 d-flex pb-15 mb-10">
        <p class="sub-title weight-700 mt-2">
          {{ $t('Tags') }}
        </p>
        <TagsBlock :tags='product_tags' :selectdTags='product_data.tags' v-if="product_data.tags"></TagsBlock>
      </div>
      <div class="bdbr-1 p-10 pt-5 mb-5" v-if="product_data.tags && product_data.tags.includes(41)">
        <p class="sub-title weight-700">
          {{ $t('Customer Home Page') }}
        </p>
        <div class="row">
          <div class="col-sm-6 col-md-4 py-0">
            <v-checkbox color="#0D3856" v-model="product_data.show_destop" :label="$t('Shows on desktop')"></v-checkbox>
          </div>
          <div class="col-sm-6 col-md-4 py-0">
            <v-checkbox color="#0D3856" v-model="product_data.show_tablet" :label="$t('Shows on tablet')"></v-checkbox>
          </div>
          <div class="col-sm-6 col-md-4 py-0">
            <v-checkbox color="#0D3856" v-model="product_data.show_mobile" :label="$t('Shows on mobile')"></v-checkbox>
          </div>
        </div>
      </div>

      <div class="d-flex align-items-center mb-5">
        <v-checkbox color="#0D3856" class="cus-checkbox" v-model="product_data.show_additional_details"
          id="show_addition_detail"></v-checkbox>
        <label for="show_addition_detail" class="px-5">{{ $t('Define and view additional details') }}</label>
      </div>
      <div v-show="product_data.show_additional_details" class="px-10">
        <div class="d-flex align-items-center mb-5">
          <label class="space-nowrap">{{ $t('Language') }}</label>
          <vue-select :options="language.options" class="input cus-select default-lang"
            v-model="language.selected"></vue-select>
        </div>
        <div class="d-flex align-items-center">
          <v-checkbox color="#0D3856" class="cus-checkbox" v-model="isDefaultLang" @change="changeDefaultLang"
            id="default_language"></v-checkbox>
          <label for="default_language" class="px-5">{{ $t('Default Language') }}</label>
        </div>
        <div v-if="language.selected.code == 'HE'">
          <label>{{ $t('Additional Detail') }}: {{ $t('Hebrew') }}</label>
          <vue-editor v-model="product_data.additional_details_he" class="bg-white mb-10"></vue-editor>
        </div>
        <div v-if="language.selected.code == 'EN'">
          <label>{{ $t('Additional Detail') }}: {{ $t('English') }}</label>
          <vue-editor v-model="product_data.additional_details_en" class="bg-white mb-10"></vue-editor>
        </div>
        <div v-if="language.selected.code == 'AR'">
          <label>{{ $t('Additional Detail') }}: {{ $t('Arabic') }}</label>
          <vue-editor v-model="product_data.additional_details_ar" class="bg-white mb-10"></vue-editor>
        </div>
      </div>

      <div class="d-flex bottom-btns">
        <button class="primary-btn" @click="save()">
          {{ $t('Save') }}
        </button>
        <button class="primary-btn bg-yellow mx-10" @click="openCreateModal()">
          {{ $t('New') }}
        </button>
      </div>
    </div>
    <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
    <CreateProductModal :supplier_items="supplier_items" :product_tags="product_tags"></CreateProductModal>
  </main>
</template>    

<script>
import { mapState, mapActions } from 'vuex';
import TagsBlock from '../../../components/TagsBlock'
import CreateProductModal from '../../../components/management/CreateProductModal'
export default {
  name: 'ProductManagement',
  components: {
    TagsBlock,
    CreateProductModal
  },
  data() {
    return {
      test: {
        selected: { label: 'הכל', code: 0 },
        options: [{ label: 'הכל', code: 0 }],
      },
      currencyList: {
        selected: { code: 'ILS', label: '₪' },
        options: [
          { code: 'ILS', label: '₪' },
          { code: 'USD', label: '$' },
          { code: 'EUR', label: '€' }
        ]
      },
      profitList: {
        selected: { code: 'profit', label: this.$t("% profit") },
        options: [
          { code: 'profit', label: this.$t("% profit") },
          { code: 'commission', label: this.$t('Commission') },
        ]
      },
      showAddLang: false,
      supplierCategory: {
        options: [],
        selected: ''
      },
      suppliers: {
        options: [],
        selected: ''
      },
      supplierProducts: {
        options: [],
        selected: ''
      },
      alternativeProducts: {
        options: [],
        selected: ''
      },
      cardType: {
        options: [],
        selected: ''
      },
      language: {
        options: [
          {
            code: 'HE',
            label: 'עברית'
          },
          {
            code: 'AR',
            label: 'العربية'
          },
          {
            code: 'EN',
            label: 'English'
          },
        ],
        selected: {
          code: 'HE',
          label: 'עברית'
        }
      }
    };
  },
  async created() {
    await this.getAllSupplierItems();
    if (this.product_tags.length == 0) {
      await this.getProductTags();
    }
    if (this.supplier_items) {
      for (let supplierType in this.supplier_items) {
        this.supplierCategory.options.push(supplierType);
      }

      if (this.storageSupplierCategoryId != "undefined") {
        this.supplierCategory.selected = this.storageSupplierCategoryId;
      } else {
        this.supplierCategory.selected = this.supplierCategory.options[0];
      }
      this.changeCategory();
    }
  },
  computed: {
    ...mapState({
      supplier_loading: (state) => state.supplier.loading,
      product_loading: (state) => state.product.setLoadingTrue,
      product_module_loading: (state) => state.product.product_module_loading,
      supplier_items: state => state.supplier.supplier_items,
      special_products: state => state.supplier.special_products,
      product_data: state => state.supplier.product_data,
      product_tags: state => state.product.product_tags,
    }),
    loading() {
      return this.supplier_loading || this.product_loading || this.product_module_loading;
    },
    isDefaultLang: {
      get() {
        return this.product_data.additional_details_default_language == this.language.selected.code;
      },
      set() {
        return this.product_data.additional_details_default_language == this.language.selected.code;
      }
    },
    storageSupplierCategoryId() {
      return localStorage.getItem("cpSupplierCategory");
    },
    storageSuppliersId() {
      return localStorage.getItem("cpSuppliersId");
    },
    storageProductId() {
      return localStorage.getItem("cpProductId");
    }
  },
  methods: {
    ...mapActions('supplier', {
      getAllSupplierItems: 'getAllSupplierItems',
      getProductsById: 'getProductsById',
      getProductDataById: 'getProductDataById',
      updateProduct: 'updateProduct'

    }),
    ...mapActions('product', {
      getProductTags: 'getProductTags',
    }),
    changeCategory() {
      this.suppliers = {
        options: [],
        selected: ''
      };
      const supplierType = this.supplierCategory.selected;
      const supplierTypeItems = this.supplier_items[supplierType];
      for (let supplerData of supplierTypeItems) {
        this.suppliers.options.push({
          code: supplerData.id,
          label: supplerData.name
        });
      };
      if (this.storageSuppliersId != "undefined") {
        const supplierTemp = this.suppliers.options.find((el) => {
          return el.code == this.storageSuppliersId;
        });
        if (supplierTemp) {
          this.suppliers.selected = supplierTemp;
        } else {
          this.suppliers.selected = this.suppliers.options[0];
        }
      } else {
        this.suppliers.selected = this.suppliers.options[0];
      }

      this.changeSupplier();
    },
    async changeSupplier() {
      const currentSupplierId = this.suppliers.selected.code;
      localStorage.setItem("cpSupplierCategory", this.supplierCategory.selected);
      localStorage.setItem("cpSuppliersId", currentSupplierId);

      await this.getProductsById({
        supplier_id: currentSupplierId
      });
      this.supplierProducts = {
        options: [],
        selected: ''
      };
      this.cardType = {
        options: [
          this.$t('All')
        ],
        selected: this.$t('All')
      };
      for (let type in this.special_products) {
        this.special_products[type].forEach(el => {
          this.supplierProducts.options.push(
            {
              label: el.name,
              code: el.id
            }
          )
        });
        this.cardType.options.push(type);
      }
      if (this.storageProductId != "undefined") {
        const productTemp = this.supplierProducts.options.find((el) => {
          return el.code == this.storageProductId;
        });

        if (productTemp) {
          this.supplierProducts.selected = productTemp;
        } else {
          this.supplierProducts.selected = this.supplierProducts.options[0];
        }
      } else {
        this.supplierProducts.selected = this.supplierProducts.options[0];
      }

      this.alternativeProducts = JSON.parse(JSON.stringify(this.supplierProducts));
      this.changeProduct();
    },
    async changeProduct() {
      const currentProductId = this.supplierProducts.selected.code;
      localStorage.setItem("cpProductId", currentProductId);
      await this.getProductDataById({
        product_id: currentProductId
      });

      if (Object.keys(this.product_data).length) {
        if (this.product_data.currency) {
          this.currencyList.selected.code = this.product_data.currency;
        } else {
          this.currencyList.selected = this.currencyList.options[0];
        }

        if (this.product_data.profit_model) {
          this.profitList.selected.code = this.product_data.profit_model;
        } else {
          this.profitList.selected = this.profitList.options[0];
        }

        if (this.product_data.upscale_product_id) {
          this.alternativeProducts.selected.code = this.product_data.upscale_product_id;
        } else {
          this.alternativeProducts.selected = '';
        }
        if (this.product_data.status == 1) {
          this.product_data.status = true;
        } else {
          this.product_data.status = false;
        }
      }
    },
    async changeType() {
      const selectedType = this.cardType.selected;
      this.supplierProducts = {
        options: [],
        selected: ''
      };
      if (selectedType == this.$t('All')) {
        for (let type in this.special_products) {
          this.special_products[type].forEach(el => {
            this.supplierProducts.options.push(
              {
                label: el.name,
                code: el.id
              }
            )
          });
        }
      } else {
        this.special_products[selectedType].forEach(el => {
          this.supplierProducts.options.push(
            {
              label: el.name,
              code: el.id
            }
          )
        });
      }

      this.supplierProducts.selected = this.supplierProducts.options[0];
      this.alternativeProducts = JSON.parse(JSON.stringify(this.supplierProducts));
      this.changeProduct();
    },
    changeDefaultLang() {
      this.product_data.additional_details_default_language = this.language.selected.code;
    },
    async save() {
      let formData = new FormData();

      formData.append('call_minute', this.product_data.call_minute || '');
      formData.append('sms', this.product_data.sms || '');
      formData.append('browsing_package', this.product_data.browsing_package || '');
      formData.append('usage_time_limit', this.product_data.usage_time_limit || '');
      formData.append('call_to_palestinian', true || '');
      formData.append('abroed_price', this.product_data.abroed_price || '');
      formData.append('name_he', this.product_data.name_he || '');
      formData.append('name_en', this.product_data.name_en || '');
      formData.append('name_ar', this.product_data.name_ar || '');
      formData.append('description_he', this.product_data.description_he || '');
      formData.append('description_en', this.product_data.description_en || '');
      formData.append('description_ar', this.product_data.description_ar || '');
      formData.append('supplier_identity_number', this.product_data.supplier_identity_number || '');
      // formData.append('barcodes', JSON.stringify(this.product_data.barcodes) || '');
      formData.append('barcodes', []);
      formData.append('tags', JSON.stringify(this.product_data.tags) || []);
      formData.append('price', this.product_data.price || '');
      formData.append('customer_price', this.product_data.customer_price || '');
      formData.append('manual_price', this.product_data.manual_price);
      formData.append('currency', this.currencyList.selected.code || '');
      formData.append('distribution_fee', this.product_data.distribution_fee || '');
      formData.append('status', this.product_data.status);
      formData.append('default_is_allow', this.product_data.default_is_allow);
      formData.append('cancelable', this.product_data.cancelable);
      formData.append('add_vat', this.product_data.add_vat);
      formData.append('provider_type', this.product_data.provider_type);
      formData.append('profit_model', this.profitList.selected.code || '');
      formData.append('call_terms', this.product_data.call_terms || '');
      formData.append('other1', this.product_data.other1 || '');
      formData.append('other2', this.product_data.other2 || '');
      formData.append('order', this.product_data.order || '');
      formData.append('buying_price', this.product_data.buying_price || '');
      formData.append('additional_details_default_language', this.product_data.additional_details_default_language || '');
      formData.append('show_additional_details', this.product_data.show_additional_details);
      formData.append('additional_details_he', this.product_data.additional_details_he || '');
      formData.append('additional_details_en', this.product_data.additional_details_en || '');
      formData.append('additional_details_ar', this.product_data.additional_details_ar || '');
      formData.append('show_destop', this.product_data.show_destop || false);
      formData.append('show_tablet', this.product_data.show_tablet || false);
      formData.append('show_mobile', this.product_data.show_mobile || false);

      await this.updateProduct({
        id: this.product_data.id,
        params: formData
      });

      this.changeProduct();
    },
    openCreateModal() {
      this.$modal.show("CreateProductModal");
    }
  }
};
</script>
<style lang="scss">
.cus-select .vs__selected {
  font-weight: 400;
}
</style>

<style lang="scss" scoped>
label {
  font-size: 16px;
  margin-bottom: 0;
}

.main-form {
  margin: 20px 40px 0 40px;

  .form-block {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .form-item {
      width: calc(25% - 30px);

      .input {
        height: 32px;
      }

      .add-lang_btn {
        height: 32px;
        margin-top: 32px;
      }

      &.width-33 {
        width: calc(33% - 15px);
      }
    }

    .space-form-item_3 {
      display: none;
    }

    .form-checkbox {
      display: flex;
      align-items: center;
    }
  }

  .bottom-btns {
    margin-bottom: 50px;

    .primary-btn {
      width: 200px;
      height: 40px;
    }
  }
}

.arrow-top {
  position: absolute;
  overflow: hidden;
  width: 30px;
  height: 15px;
  left: 50%;
  margin-left: -15px;

  &::after {
    content: "";
    background: #B5D3FF;
    width: 20px;
    height: 20px;
    position: absolute;
    transform-origin: 0 0;
    left: 15px;
    top: 0;
    transform: rotate(45deg);
  }
}

.translate-img {
  width: 30px;
  height: 30px;
}

.add-lang-block {
  position: absolute;
  top: -20px;
  background-color: #B5D3FF;
  padding: 30px 0;
  z-index: 9;

  .translate-img {
    width: 56px;
    height: 56px;
    left: 0;
  }
}

.default-lang {
  width: 140px;
  margin: 0 20px;
}

@media screen and (max-width: 1100px) {
  .main-form {
    .form-block {
      .form-item {
        width: calc(33% - 20px);
      }

      .space-form-item_4 {
        display: none;
      }

      .space-form-item_3 {
        display: block;
      }
    }
  }

  .add-lang-block {
    top: -5px;

    .w-25 {
      width: 33% !important;
    }
  }
}

@media screen and (max-width: 768px) {
  .main-form {
    .form-block {
      .form-item {
        width: calc(50% - 20px);

        &.width-33 {
          width: calc(50% - 15px);
        }
      }
    }
  }

  .add-lang-block {
    .w-25 {
      width: 50% !important;
    }
  }
}

@media screen and (max-width: 600px) {
  .main-form {
    margin: 20px;

    .form-block {
      .form-item {
        width: 100%;

        &.width-33 {
          width: 100%;
        }
      }
    }
  }

  .add-lang-block {
    .w-25 {
      width: 100% !important;
    }
  }
}
</style>