<template>
  <modal
    :width="1400"
    :adaptive="true"
    class="createProductModal CEModal modal-scroll-bar"
    name="CreateProductModal"
  >
    <div class="modal-header">
      <button class="button cus-close-modal_btn" @click="closeModal()">
        <img src="/assets/img/close.svg" alt />
      </button>
    </div>
    <div class="">
      <p class="main-title weight-700">
        {{ $t('Products') }}
      </p>
      <div class="main-form">
        <div class="px-10 mb-10">
          <div class="form-block">
            <div class="form-item">
              <label>{{ $t('Select a category') }}</label>
              <vue-select 
                :options="supplierCategory.options" 
                class="input cus-select"
                v-model="supplierCategory.selected"
                @input="changeCategory"
              ></vue-select>
            </div>
            <div class="form-item">
              <label>{{ $t('Select a supplier') }}</label>
              <vue-select 
                :options="suppliers.options" 
                class="input cus-select"
                v-model="suppliers.selected"
              ></vue-select>
            </div>
            <div class="form-item">
              <label>{{ $t('Product Type') }}</label>
              <vue-select 
                :options="cardType.options" 
                class="input cus-select"
                v-model="cardType.selected"
              ></vue-select>
            </div>
            <div class="form-item space-form-item_4">
            </div>
            <div class="form-item">
              <label>{{ $t('Product name: Hebrew') }}</label>
              <div class="mt-2 mb-7 icon-input">
                <input type="text" class="input" v-model="product_data.name_he" />
                <img src="/assets/img/icons/tranlate.png" alt="" class="translate-img h-100">
              </div>
            </div>
            <div class="form-item">
              <label>{{ $t('Product name: English') }}</label>
              <div class="mt-2 mb-7 icon-input">
                <input type="text" class="input" v-model="product_data.name_en" />
                <img src="/assets/img/icons/tranlate.png" alt="" class="translate-img h-100">
              </div>
            </div>
            <div class="form-item">
              <label>{{ $t('Product name: Arabic') }}</label>
              <div class="mt-2 mb-7 icon-input">
                <input type="text" class="input" v-model="product_data.name_ar" />
                <img src="/assets/img/icons/tranlate.png" alt="" class="translate-img h-100">
              </div>
            </div>
            <div class="form-item space-form-item_4">
            </div>
          </div>
        </div>
        <div class="bdbr-1 px-10 mb-5">
          <p class="sub-title weight-700 mt-2">
            {{ $t('Product Details') }}
          </p>
          <div class="form-block">
            <div class="form-item">
              <label>{{ $t('Minutes of conversation') }}</label>
              <input type="text" class="input mt-2 mb-7" v-model="product_data.call_minute" />
            </div>
            <div class="form-item">
              <label>{{ $t('SMS messages') }}</label>
              <input type="text" class="input mt-2 mb-7"  v-model="product_data.sms" />
            </div>
            <div class="form-item">
              <label>{{ $t('Mobile browsing') }}</label>
              <input type="text" class="input mt-2 mb-7" v-model="product_data.browsing_package" />
            </div>
            <div class="form-item">
              <label>{{ $t('Terms of calls') }}</label>
              <input type="text" class="input mt-2 mb-7" v-model="product_data.call_terms"/>
            </div>
            <div class="form-item">
              <label>{{ $t('General') }} 1</label>
              <input type="text" class="input mt-2 mb-7" v-model="product_data.other1"/>
            </div>
            <div class="form-item">
              <label>{{ $t('General') }} 2</label>
              <input type="text" class="input mt-2 mb-7" v-model="product_data.other2"/>
            </div>
            <div class="form-item">
              <label>{{ $t('Price use abroad Israel') }}</label>
              <input type="text" class="input mt-2 mb-7"  v-model="product_data.abroed_price" />
            </div>
            <div class="form-item">
              <label>{{ $t('Valid on days') }}</label>
              <input type="text" class="input mt-2 mb-7" v-model="product_data.usage_time_limit" />
            </div>
            <div class="form-item space-form-item_3">
            </div>
          </div>
        </div>
        <div class="px-10 mb-5">
          <div class="form-block align-items-center">
            <div class="form-checkbox">
              <v-checkbox color="#0D3856" class="cus-checkbox" v-model="product_data.status"></v-checkbox>
              <label class="px-5">{{ $t('Active') }}</label>
            </div>
            <div class="form-checkbox">
              <v-checkbox color="#0D3856" class="cus-checkbox" v-model="product_data.cancelable"></v-checkbox>
              <label class="px-5">{{ $t('Authorized for revocation') }}</label>
            </div>
            <div class="form-checkbox">
              <v-checkbox color="#0D3856" class="cus-checkbox" v-model="product_data.default_is_allow"></v-checkbox>
              <label class="px-5">{{ $t('Default in setting up a reseller') }}</label>
            </div>
            <div class="form-checkbox">
              <v-checkbox color="#0D3856" class="cus-checkbox" v-model="product_data.add_vat"></v-checkbox>
              <label class="px-5">{{ $t('Product without VAT') }}</label>
            </div>
            <div class="form-item">
              <vue-select
                :options="test.options"
                class="input cus-select"
                v-model="test.selected"
              ></vue-select>
            </div>
          </div>
        </div>
        <div class="bdbr-1 px-10 mb-10">
          <p class="sub-title weight-700 mt-2">
            {{ $t('Product settings') }}
          </p>
          <div class="form-block">
            <div class="form-item">
              <label>{{ $t('ID at the supplier') }}</label>
              <input type="text" class="input mt-2 mb-7" v-model="product_data.supplier_identity_number" />
            </div>
            <div class="form-item">
              <label>{{ $t('iCount Product Number in') }}</label>
              <input type="text" class="input mt-2 mb-7" />
            </div>
            <div class="form-item">
              <label>{{ $t('Order') }}</label>
              <input type="text" class="input mt-2 mb-7" v-model="product_data.order" />
            </div>
            <div class="form-item">
              <label>{{ $t('Profit model') }}</label>
              <vue-select
                :options="profitList.options"
                class="input cus-select mt-2 mb-7"
                v-model="profitList.selected"
              ></vue-select>
            </div>
            <div class="form-item">
              <label>{{ $t('Business Sale Price') }}</label>
              <input type="text" class="input mt-2 mb-7" v-model="product_data.price" />
            </div>
            <div class="form-item">
              <label>{{ $t('Customer Sale Price') }}</label>
              <input type="text" class="input mt-2 mb-7" v-model="product_data.customer_price" />
            </div>
            <div class="form-item">
              <label>{{ $t('Distribution fee') }}</label>
              <input type="text" class="input mt-2 mb-7" v-model="product_data.distribution_fee" />
            </div>
            <div class="form-item">
              <label>{{ $t('Purchase price') }}</label>
              <input type="text" class="input mt-2 mb-7" v-model="product_data.buying_price" />
            </div>
            <div class="form-item">
              <label>{{ $t('Currency') }}</label>
              <vue-select
                :options="currencyList.options"
                class="input cus-select mt-2 mb-7"
                v-model="currencyList.selected"
              ></vue-select>
            </div>
            <div class="form-item space-form-item_3">
            </div>
            <div class="form-item space-form-item_4">
            </div>
            <div class="form-item space-form-item_4">
            </div>
          </div>
        </div>
        <div class="bdbr-1 px-10 d-flex pb-15 mb-5">
          <p class="sub-title weight-700 mt-2">
            {{ $t('Tags') }}
          </p>
          <TagsBlock :tags='product_tags' :selectdTags='product_data.tags'></TagsBlock>
        </div>
        <div class="d-flex align-items-center mb-5">
          <v-checkbox color="#0D3856" class="cus-checkbox" v-model="isAddtionalDetail"></v-checkbox>
          <label class="px-5">{{ $t('Define and view additional details') }}</label>
        </div>
        <vue-editor v-model="addtionalDetail" class="bg-white mb-10" v-show="isAddtionalDetail"></vue-editor>

        <div class="d-flex bottom-btns">
          <button class="primary-btn" @click="save()">
            {{ $t('Save') }}
          </button>
          <button class="primary-btn bg-red1 mx-10" @click="closeModal()">
            {{ $t('Cancel') }}
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import TagsBlock from '../TagsBlock.vue'
export default {
  name: "CreateProductModal",
  components: {
    TagsBlock
  },
  props: {
    supplier_items: {
      require: true,
      type: Object      
    },
    product_tags: {
      require: true,
      type: Array      
    }
  },
  watch: {
    supplier_items: function() {
      this.createSupplierOptions();
    }
  },
  data() {
    return {
      test: {
        selected: { label: 'הכל', code: 0 },
        options: [{ label: 'הכל', code: 0 }],
      },
      currencyList: {
        selected: {code: 'ILS', label: '₪'},
        options: [
          {code: 'ILS', label: '₪'},
          {code: 'USD', label: '$'},
          {code: 'EUR', label: '€'}
        ]
      },
      profitList: {
        selected: {code: 'profit', label: this.$t("% profit")},
        options: [
          {code: 'profit', label: this.$t("% profit")},
          {code: 'commission', label: this.$t('Commission')},
        ]
      },
      addtionalDetail: '',
      isAddtionalDetail: false,
      supplierCategory: {
        options: [],
        selected: ''
      },
      suppliers: {
        options: [],
        selected: ''
      },
      cardType: {
        options: [],
        selected: ''
      },
      product_data: {
        call_minute: '',
        sms: '',
        browsing_package: '',
        call_terms: '',
        abroed_price: '',
        other1: '',
        other2: '',
        name_he: '',
        name_en: '',
        name_ar: '',
        supplier_identity_number: '',
        tags: [],
        price: '',
        buying_price: '',
        distribution_fee: '',
        order: '',
        // more_info_site_url: '', 
        // more_info_description: '',
        // more_info_youtube_url: '',
        usage_time_limit: '',
        status: '',
        cancelable: '',
        default_is_allow: '',
        add_vat: '',
      }
    };
  },
  async created () {
    // console.log("----", Object.keys(this.supplier_items).length)
    // // if (Object.keys(this.supplier_items).length) {
    // if (this.supplier_items) {
    //   for (let supplierType in this.supplier_items) {
    //     this.supplierCategory.options.push(supplierType);
    //   }
    //   this.supplierCategory.selected = this.supplierCategory.options[0];
    //   this.changeCategory();
    // }

    this.createSupplierOptions();

    if (this.product_type.length == 0) {
      await this.getProductType();
    }
    this.product_type.forEach(el => {
      this.cardType.options.push({
        label: el.name,
        code: el.id
      });  
    });
    if (this.cardType.options.length) {
      this.cardType.selected = this.cardType.options[0];
    }
  },
  computed: {
    ...mapState({
      product_type: state => state.product.product_type,
    }),
  },
  methods: {
    ...mapActions('product', {
      getProductType: 'getProductType',
      saveProduct: 'saveProduct'
    }),
    closeModal() {
      this.$modal.hide('CreateProductModal');
    },
    createSupplierOptions() {
      if (Object.keys(this.supplier_items).length) {
        for (let supplierType in this.supplier_items) {
          this.supplierCategory.options.push(supplierType);
        }
        this.supplierCategory.selected = this.supplierCategory.options[0];
        this.changeCategory();
      }
    },
    changeCategory() {
      this.suppliers = {
        options: [],
        selected: ''
      };
      const supplierType = this.supplierCategory.selected;
      const supplierTypeItems = this.supplier_items[supplierType];
      for (let supplerData of supplierTypeItems) {
        this.suppliers.options.push({
          code: supplerData.id, 
          label: supplerData.name
        });
      };
      this.suppliers.selected = this.suppliers.options[0];
    },
    async save() {
      let formData = new FormData();

      formData.append('call_minute', this.product_data.call_minute || '');
      formData.append('sms', this.product_data.sms || '');
      formData.append('browsing_package', this.product_data.browsing_package || '');
      formData.append('call_terms', this.product_data.call_terms || '');
      formData.append('abroed_price', this.product_data.abroed_price || '');
      formData.append('other1', this.product_data.other1 || '');
      formData.append('other2', this.product_data.other2 || '');
      formData.append('name_he', this.product_data.name_he || '');
      formData.append('name_en', this.product_data.name_en || '');
      formData.append('name_ar', this.product_data.name_ar || '');
      formData.append('supplier_identity_number', this.product_data.supplier_identity_number || '');
      formData.append('barcodes', []);
      formData.append('tags', JSON.stringify(this.product_data.tags) || []); 
      formData.append('price', this.product_data.price || '');
      formData.append('customer_price', this.product_data.customer_price || '');
      formData.append('buying_price', this.product_data.buying_price || '');
      formData.append('currency', this.currencyList.selected.code || '');
      formData.append('distribution_fee', this.product_data.distribution_fee || '');
      formData.append('order', this.product_data.order || '');
      formData.append('profit_model', this.profitList.selected.code || '');
      formData.append('type', this.cardType.selected.code || '');
      formData.append('supplier_id', this.suppliers.selected.code || '');
      
      formData.append('usage_time_limit', this.product_data.usage_time_limit || '');
      formData.append('call_to_palestinian', true || '');
      formData.append('status', this.product_data.status || '');
      formData.append('default_is_allow', this.product_data.default_is_allow || '');
      formData.append('cancelable', this.product_data.cancelable || '');
      formData.append('add_vat', this.product_data.add_vat || '');
      
      await this.saveProduct({
        params: formData
      });

      this.closeModal();
    },
  },

}
</script>

<style lang="scss">
.createProductModal {
  .vm--modal {
    max-height: 85% !important;
  }
}
.main-form {
  margin: 20px 50px 0 50px;   
  .form-block {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .form-item {
      width: calc(25% - 30px);
      .input {
        height: 32px;
      }
      .add-lang_btn {
        height: 32px;
        margin-top: 32px;
      }
    }
    .space-form-item_3 {
      display: none;
    }
    .form-checkbox {
      display: flex;
      align-items: center;
    }
  }
  .bottom-btns {
    margin-bottom: 50px;
    .primary-btn {
      width: 200px;
      height: 40px;
    }
  }
}

</style>

<style lang="scss" scoped>
</style>
