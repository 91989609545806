import { render, staticRenderFns } from "./TagsBlock.vue?vue&type=template&id=c46cbbea&scoped=true&"
import script from "./TagsBlock.vue?vue&type=script&lang=js&"
export * from "./TagsBlock.vue?vue&type=script&lang=js&"
import style0 from "./TagsBlock.vue?vue&type=style&index=0&id=c46cbbea&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c46cbbea",
  null
  
)

export default component.exports