<template>
  <div class="form-block px-10">
    <div v-for="tag in tags" :key="tag.id">
      <div
        class="tag-btn pointer"
        @click="tagClick(tag.id)"
        v-bind:class="{ 'tag-btn_active': selectdTags.includes(tag.id) }"
        :ref="'tag' + tag.id"
      >
        {{ tag.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TagsBlock',
  props: {
    tags: Array,
    selectdTags: Array
  }, 
  methods: {
    tagClick(id) {
      if (this.$refs['tag'+id][0].classList) {
        if (!this.selectdTags.includes(id)) {
          this.selectdTags.push(id);
          this.$refs['tag'+id][0].classList.add("tag-btn_active");
        } else {
          this.removeA(this.selectdTags, id);
          this.$refs['tag'+id][0].classList.remove("tag-btn_active");
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .tag-btn {
    border: 1px solid #03B2FF;
    font-size: 14px;
    font-weight: 700;
    min-width: 120px;
    height: 26px;
    margin: 15px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
  }
  .tag-btn_active {
    background-color: #03B2FF;
  }
</style>